import Dashboard from "../pages/dashboard/Dashboard";
import CreateAdminAccount from "../pages/accountManagement/CreateAdminAccount";
import ViewEditAdminAccount from "../pages/accountManagement/ViewEditAdminAccount";
import UnAuthorized from "../pages/authentication/UnAuthorized";
import PageNotFound from "../pages/pageNotFound/PageNotFound";
import MembershipPricing from "../pages/membership/membershipPricing/MembershipPricing";
import MembershipCampaign from "../pages/membership/membershipCampaign/MembershipCampaign";
import MembershipTaskList from "../pages/membership/membershipPricing/membershipTaskList/MembershipTaskList";
import IndividualMembershipTaskList from "../pages/membership/membershipPricing/membershipTaskList/individualTaskList/IndividualMembershipTaskList";
import AddEditMembershipCampaign from "../pages/membership/membershipCampaign/membershipAddEditCompaign/AddEditMembershipCampaign";
import ViewCorporateMembers from "../pages/membership/corporateMembers/ViewCorporateMembers";
import ViewIndividualMembers from "../pages/membership/individualMembers/ViewIndividualMembers";
import CreateIndividualMembers from "../pages/membership/individualMembers/CreateIndividualMembers";
import CreateCorporateMembers from "../pages/membership/corporateMembers/CreateCorporateMembers";
import DropdownManagement from "../pages/fieldManagement/DropdownManagement";
import ManageTags from "../pages/fieldManagement/ManageTags";
import EmailTemplateManagement from "../pages/fieldManagement/EmailTemplateManagement";
import CreateEmailTemplate from "../components/fieldManagement/CreateEmailTemplate";
import MarketingTrackingURL from "../pages/entrymanagement/MarketingTrackingURL";
import EventFeedbackQuestion from "../pages/entrymanagement/EventFeedbackQuestion";
import FormQuestionAnswer from "../pages/entrymanagement/FormQuestionAnswer";
import ViewEditEntries from "../pages/entrymanagement/ViewEditEntries";
import CategoryCampaignTagging from "../pages/entrymanagement/CategoryCampaignTagging";
import CreateEntry from "../pages/entrymanagement/CreateEntry";

// Financial Start
import GlobalSettingFinance from "../pages/financial/GlobalSettingFinance";
import ChartsOfAccounts from "../pages/financial/ChartsOfAccounts";
import PaymentReconcilation from "../pages/financial/PaymentReconcilation";
import CreditNoteApprovalProcess from "../pages/financial/CreditNoteApprovalProcess";
import BatchJobExtraction from "../pages/financial/BatchJobExtraction";
import CreditNoteApproval from "../pages/financial/CreditNoteApproval";
import AddEditExternalInvoice from "../pages/financial/AddEditExternalInvoice";
import CreditNoteManagement from "../pages/financial/CreditNoteManagement";
import ExternalInvoices from "../pages/financial/ExternalInvoices";
import ViewBookingDetail from "../pages/financial/ViewBookingDetail";
import Accountpayable from "../pages/financial/Accountpayable";
import VoucherCodeManagement from "../pages/financial/VoucherCodeManagement";
import CreateVoucherCode from "../pages/financial/CreateVoucherCode";
import EventRegistrationApproval from "../pages/financial/EventRegistrationApproval";
import ExtractInvoices from "../pages/financial/ExtractInvoices";
import MembershipReport from "../pages/membership/MembershipReport";
import ARAgingReport from "../pages/financial/ARAgingReport";
import GSTReport from "../pages/financial/GSTReport";
import VoucherCodeReport from "../pages/financial/VoucherCodeReport";
import TransactionReport from "../pages/financial/TransactionReport";
// import OrganisationRoleReport from "../pages/financial/OrganisationRoleReport";
import OrganisationRoleReport from "../pages/membership/OrganisationRoleReport";

import DeferredIncomeReport from "../pages/financial/DeferredIncomeReport";
import XeroInvoiceExtraction from "../pages/financial/XeroInvoiceExtraction";
import ViewBookingCancellation from "../pages/booking/ViewBookingCancellation";
import ViewNewBookings from "../pages/booking/ViewNewBookings";
import NewBookingCancellationDetail from "../pages/booking/NewBookingCancellationDetail";
import PaymentReconciliationExtraction from "../pages/financial/PaymentReconciliationExtraction";

const ComponentNavigation = [
  {
    path: "Dashboard",
    title: "Dashboard",
    component: Dashboard,
    hasParams: false,
    roles: [1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
  },
  {
    path: "PageNotFound",
    title: "PageNotFound",
    component: PageNotFound,
    hasParams: false,
    roles: [1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]
  },
  {
    path: "CreateAdminAccount",
    previousPath: "Account Management",
    title: "Create Admin Account",
    component: CreateAdminAccount,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "CreateAdminAccount/:adminId",
    title: "Create Admin Account",
    component: CreateAdminAccount,
    roles: [1, 8],
    hasParams: true,
  },
  {
    path: "ViewEditAdminAccount",
    previousPath: "Account Management",
    title: "View/Edit Admin Account",
    component: ViewEditAdminAccount,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "authentication/UnAuthorized",
    previousPath: "UnAuthorized",
    title: "UnAuthorized",
    component: UnAuthorized,
    roles: [1, 2, 3, 4, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    hasParams: false,
  },
  {
    path: "Membership/Pricing",
    previousPath: "Membership",
    title: "Membership Pricing",
    component: MembershipPricing,
    hasParams: false,
    roles: [1],
  },
  {
    path: "MembershipCampaign",
    previousPath: "Membership",
    title: "Membership Campaign",
    component: MembershipCampaign,
    hasParams: false,
    roles: [1],
  },
  {
    path: "Membership/MembershipTaskList",
    previousPath: "Membership",
    title: "Membership Task List (Corporate)",
    component: MembershipTaskList,
    hasParams: false,
    roles: [1, 2, 3, 15, 16],
  },
  {
    path: "Membership/MembershipTaskList/individualTaskList",
    previousPath: "Membership",
    title: "Membership Task List (Individual)",
    component: IndividualMembershipTaskList,
    hasParams: false,
    roles: [1, 2, 3, 15, 16],
  },
  {
    path: "AddEditMembershipCampaign",
    previousPath: "Membership",
    title: "Edit Membership Campaign",
    component: AddEditMembershipCampaign,
    hasParams: false,
    roles: [1, 2, 12, 14],
  },
  {
    path: "AddEditMembershipCampaign/membershipCampaignId",
    previousPath: "Membership",
    title: "Edit Membership Campaign",
    component: AddEditMembershipCampaign,
    hasParams: true,
    roles: [1, 2, 12, 14]
  },
  {
    path: "Membership/ViewCorporateMembers",
    previousPath: "Membership",
    title: "View Corporate Members",
    component: ViewCorporateMembers,
    hasParams: false,
    roles: [1, 8, 2, 3, 4, 14, 11, 15, 16],
  },
  {
    path: "Membership/CreateCorporateMembers",
    previousPath: "Membership",
    title: "Create Corporate Members",
    component: CreateCorporateMembers,
    hasParams: false,
    roles: [1, 2, 12, 14, 15, 3, 11, 16],
  },

  {
    path: "CreateCorporateMembers/:CorporateMemberId",
    previousPath: "Membership",
    title: "Edit Corporate Member",
    component: CreateCorporateMembers,
    hasParams: true,
    roles: [1, 2, 12, 14, 15, 3, 11, 16],
  },

  {
    path: "Membership/ViewIndividualMembers",
    previousPath: "Membership",
    title: "View Individual Members",
    component: ViewIndividualMembers,
    hasParams: false,
    roles: [1, 8, 2, 3, 4, 14, 11, 15, 3, 16],
  },
  {
    path: "Membership/CreateIndividualMembers",
    previousPath: "Membership",
    title: "Create Individual Members",
    component: CreateIndividualMembers,
    hasParams: false,
    roles: [1, 2, 12, 14, 15, 3, 11, 16],
  },
  {
    path: "CreateIndividualMembers/:individualMemberId",
    previousPath: "Membership",
    title: "Create Individual Members",
    component: CreateIndividualMembers,
    hasParams: true,
    roles: [1, 2, 12, 14, 15, 3, 11, 16],
  },
  {
    path: "Membership/OrganisationRoleReport",
    previousPath: "Membership",
    title: "Organisation Role Report",
    component: OrganisationRoleReport,
    roles: [1, 2, 15, 3, 16],
    hasParams: true,
  },
  {
    path: "Membership/MembershipReport",
    previousPath: "Membership",
    title: "Membership Report",
    component: MembershipReport,
    roles: [1, 2, 4, 15, 3, 16],
    hasParams: true,
  },
  {
    path: "DropdownManagement",
    previousPath: "Field Management",
    title: "Dropdown Management",
    component: DropdownManagement,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "ManageTags",
    previousPath: "Field Management",
    title: "Manage Tags",
    component: ManageTags,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "EmailTemplateManagement",
    previousPath: "Field Management",
    title: "Email Template Management",
    component: EmailTemplateManagement,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "CreateEmailTemplate",
    previousPath: "Field Management",
    title: "Create Email Template",
    component: CreateEmailTemplate,
    roles: [1, 8],
    hasParams: false,
  },

  {
    path: "CreateEmailTemplate/:emailTemplateId",
    previousPath: "Field Management",
    title: "Create Email Template",
    component: CreateEmailTemplate,
    roles: [1, 8],
    hasParams: true,
  },
  {
    path: "MarketingTrackingURL",
    previousPath: "Entry Management",
    title: "Marketing Tracking URL",
    component: MarketingTrackingURL,
    hasParams: true,
    roles: [1, 14, 16]
  },
  {
    path: "EventFeedbackQuestion",
    previousPath: "Entry Management",
    title: "View / Edit Entries",
    component: EventFeedbackQuestion,
    roles: [1, 11,14, 16],
    hasParams: true,
  },
  {
    path: "FormQuestionAnswer",
    previousPath: "Entry Management",
    title: "View / Edit Entries",
    component: FormQuestionAnswer,
    roles: [1, 11,14, 16],
    hasParams: true,
  },
  {
    path: "ViewEditEntries",
    previousPath: "Event Management",
    title: "View / Edit Event",
    component: ViewEditEntries,
    roles: [1, 8, 11, 14, 16],
    hasParams: true,
  },
  {
    path: "CreateEntry",
    previousPath: "Event Management",
    title: "Create Events",
    component: CreateEntry,
    roles: [1, 2, 11,14, 16],
    hasParams: true,
  },
  {
    path: "CreateEntry/:eventId",
    title: "Update Event Details",
    component: CreateEntry,
    roles: [1, 2, 11,14, 16],
    hasParams: true,
  },
  {
    path: "CategoryCampaignTagging",
    previousPath: "View/Edit Entry",
    title: "Category & Campaign Tagging",
    component: CategoryCampaignTagging,
    roles: [1, 9, 10, 11, 12, 2, 13, 14, 16],
    hasParams: true,
  },
  {
    path: "CategoryCampaignTagging/:eventId",
    previousPath: "View/Edit Entry",
    title: "Category & Campaign Tagging",
    component: CategoryCampaignTagging,
    roles: [1, 9, 10, 11, 12, 2, 13, 14, 16],
    hasParams: true,
  },
  // Financial
  {
    path: "financial/EventRegistrationApproval",
    previousPath: "Financial",
    title: "Event Registration Approval ",
    component: EventRegistrationApproval,
    roles: [1, 2, 14, 11, 16],
    hasParams: false,
  },
  {
    path: "financial/PaymentReconciliation",
    previousPath: "Financial",
    title: "Payment Reconciliation",
    component: PaymentReconcilation,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "financial/GlobalSettingFinance",
    previousPath: "Financial",
    title: "Global Setting Finance",
    component: GlobalSettingFinance,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "financial/ChartsOfAccounts",
    previousPath: "Financial",
    title: "Charts of Accounts",
    component: ChartsOfAccounts,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "financial/BatchJobExtraction",
    previousPath: "Financial",
    // title: "Batch Job Extraction",
    title: "Business Transaction Batch Job",
    component: BatchJobExtraction,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "financial/CreditNoteApproval",
    previousPath: "Financial",
    title: "Credit Note Approval",
    component: CreditNoteApproval,
    roles: [1, 3, 4],
    hasParams: false,
  },
  {
    path: "financial/AddEditExternalInvoice",
    previousPath: "Financial",
    title: "External Invoices",
    component: AddEditExternalInvoice,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "financial/CreditNoteManagement/:CreditNoteId",
    previousPath: "Financial",
    title: "Credit Note Management ",
    component: CreditNoteManagement,
    roles: [1, 3, 4],
    hasParams: false,
  },
  {
    path: "financial/ExternalInvoices",
    previousPath: "Financial",
    title: "External Invoices ",
    component: ExternalInvoices,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "financial/AddEditExternalInvoice/:financeExternalInvoiceId",
    previousPath: "Financial",
    title: "External Invoices ",
    component: AddEditExternalInvoice,
    roles: [1, 3],
    hasParams: true,
  },
  {
    path: "financial/ViewBookingDetail/:bookingAttendeeInformationId/:bookingId",
    previousPath: "Financial",
    title: "View Booking Detail ",
    component: ViewBookingDetail,
    roles: [1, 2, 10, 9, 12, 11, 13, 14],
    hasParams: true,
  },
  {
    path: "financial/VoucherCodeManagement",
    previousPath: "Financial",
    title: "Voucher Code Management",
    component: VoucherCodeManagement,
    roles: [1, 3, 4, 14, 16],
    hasParams: true,
  },

  {
    path: "createVoucherCode/:voucherCodeId",
    previousPath: "VoucherCodeManagement",
    title: "Create Voucher Code",
    component: CreateVoucherCode,
    roles: [1, 3, 4, 14, 16],
    hasParams: true,
  },
  {
    path: "createVoucherCode",
    previousPath: "VoucherCodeManagement",
    title: "Create Voucher Code",
    component: CreateVoucherCode,
    roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14, 16],
    hasParams: true,
  },
  // {
  //   path: "financial/MembershipReport",
  //   previousPath: "MembershipReport",
  //   title: "Membership Report",
  //   component: MembershipReport,
  //   roles: [1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14],
  //   hasParams: true,
  // },


  {
    path: "financial/VoucherCodeReport",
    previousPath: "VoucherCodeReport",
    title: "Voucher Code Report",
    component: VoucherCodeReport,
    roles: [1, 3, 4,14, 16],
    hasParams: true,
  },
  {
    path: "financial/ARAgingReport",
    previousPath: "ARAgingReport",
    title: "ARAging Report",
    component: ARAgingReport,
    roles: [1, 3, 4],
    hasParams: true,
  },
  {
    path: "financial/GSTReport",
    previousPath: "GSTReport",
    title: "GST Report",
    component: GSTReport,
    roles: [1, 3, 4],
    hasParams: true,
  },
  {
    path: "financial/TransactionReport",
    previousPath: "Financial",
    title: "Transaction Report",
    component: TransactionReport,
    roles: [1, 3, 4],
    hasParams: true,
  },

  {
    path: "financial/DeferredIncomeReport",
    previousPath: "Financial",
    title: "Deferred Income Report",
    component: DeferredIncomeReport,
    roles: [1, 3, 4],
    hasParams: true,
  },
  {
    path: "financial/ExtractInvoices",
    previousPath: "Financial",
    title: "Extract Invoices",
    component: ExtractInvoices,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "financial/PaymentReconciliationExtraction",
    previousPath: "Financial",
    title: "Xero AR Template",
    component: PaymentReconciliationExtraction,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "financial/XeroInvoiceExtraction",
    previousPath: "Financial",
    title: "Xero Invoice Extraction",
    component: XeroInvoiceExtraction,
    roles: [1, 3],
    hasParams: false,
  },
  // Booking Start
  {
    path: "booking/ViewNewBookings",
    previousPath: "Booking",
    title: "View New Bookings",
    component: ViewNewBookings,
    roles: [1, 2, 11, 14],
    hasParams: false,
  },
  {
    path: "booking/ViewBookingCancellation",
    previousPath: "Booking",
    title: "View New Booking Cancellation",
    component: ViewBookingCancellation,
    roles: [1, 2, 11, 14],

    hasParams: false,
  },
  {
    path: "booking/NewBookingCancellationDetail/:cancellationId",
    previousPath: "Booking",
    title: "New Booking Cancellation Detail",
    component: NewBookingCancellationDetail,
    roles: [1, 2, 11, 14],
    hasParams: false,
  },



];
export default ComponentNavigation;