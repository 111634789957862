import React from "react";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import DropdownSelect from "../../dropdown/Dropdown";
import TextInput from "../../UIComponent/TextInput";
import ValidationText from "../../../utils/validation/ValidationText";

function OrganisationBasicInformation(props) {
    console.log("OrganisationBasicInformation", props.corporateOrganisationDetails);

    return (
        <>
            <div className="grid grid-cols-12 2xl:gap-6 lg:gap-6 gap-3 lg:px-[25px] px-[15px] lg:py-[25px] py-[15px]">
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12"></div>
                <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="flield-sec">
                        <label className="text-[#181818] text-[20px] big-bullet-points font-bold mb-5 inline-block mobile-14">
                            First Name <span className="text-[#C00000]">*</span>
                        </label>
                        <div className="flex items-center left-border-none">
                            <div className="w-[130px]">
                                <DropdownSelect
                                    drpIdentity={"salutationId"}
                                    optionArray={props.salutation}
                                    setFilterParameters={props.setFilterParameters}
                                    value={props.corporateOrganisationDetails.mrMrsId}
                                    isRemoveDefaultOption={false}
                                />
                            </div>
                            <TextInput
                                handleChange={props.handleChange}
                                value={props.corporateOrganisationDetails.firstName}
                                name={"firstName"}
                                onBlur={(e) => props.validateField("firstName", "Organisation_Profile")}
                                identity="Organisation_Profile"
                            />
                        </div>
                        <ValidationText error={props.validStateRepresantativeInformation.error.salutationId} />
                        <ValidationText error={props.validStateRepresantativeInformation.error.firstName} />
                    </div>
                </div>
                <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="flield-sec">
                        <label className="text-[#181818] text-[20px] big-bullet-points font-bold mb-5 inline-block mobile-14">
                            Last Name <span className="text-[#AA3361]">*</span>
                        </label>
                        <div className="flield-sec">
                            <TextInput
                                handleChange={props.handleChange}
                                value={props.corporateOrganisationDetails.lastName}
                                name={"lastName"}
                                onBlur={() => props.validateField("lastName", "Organisation_Profile")}
                                identity="Organisation_Profile"
                            />
                            <ValidationText error={props.validStateRepresantativeInformation.error.lastName} />
                        </div>
                    </div>
                </div>
                <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="flield-sec">
                        <label className="text-[#181818] text-[20px] big-bullet-points font-bold mb-5 inline-block mobile-14">
                            Designation <span className="text-[#AA3361]">*</span>
                        </label>
                        <div className="flield-sec">
                            <TextInput
                                handleChange={props.handleChange}
                                value={props.corporateOrganisationDetails.designation}
                                name={"designation"}
                                onBlur={() => props.validateField("designation", "Organisation_Profile")}
                                identity="Organisation_Profile"
                            />
                            <ValidationText error={props.validStateRepresantativeInformation.error.designation} />
                        </div>
                    </div>
                </div>
                <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="flield-sec">
                        <label className="text-[#181818] text-[20px] big-bullet-points font-bold mb-5 inline-block mobile-14">
                            Job Position<span className="text-[#AA3361]">*</span>
                        </label>
                        <DropdownSelect
                            drpIdentity={"jobPositionId"}
                            optionArray={props.jobFunctionList}
                            setFilterParameters={props.setFilterParameters}
                            value={props.corporateOrganisationDetails.jobPositionId}
                        />
                        <ValidationText error={props.validStateRepresantativeInformation.error.jobPositionId} />
                    </div>
                </div>
                <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="flield-sec">
                        <label className="text-[#181818] text-[20px] big-bullet-points font-bold mb-5 inline-block mobile-14">
                            Business Email Address <span className="text-[#AA3361]">*</span>
                        </label>
                        <div className="flield-sec">
                            <TextInput
                                handleChange={props.handleChange}
                                value={props.corporateOrganisationDetails.businessEmail}
                                name={"businessEmail"}
                                onBlur={() => props.validateField("businessEmail", "Organisation_Profile")}
                                identity="Organisation_Profile"
                            />
                            <ValidationText error={props.validStateRepresantativeInformation.error.businessEmail} />
                        </div>
                    </div>
                </div>
                <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
                    <div className="flield-sec">
                        <label className="text-[#181818] text-[20px] big-bullet-points font-bold mb-5 inline-block mobile-14">
                            Business Contact Number <span className="text-[#AA3361]">*</span>
                        </label>
                        <div className="flield-sec">
                            <TextInput
                                handleChange={props.handleChange}
                                value={props.corporateOrganisationDetails.businessContactNo}
                                name={"businessContactNo"}
                                onBlur={() => props.validateField("businessContactNo", "Organisation_Profile")}
                                identity="Organisation_Profile"
                            />
                            <ValidationText error={props.validStateRepresantativeInformation.error.businessContactNo} />
                        </div>
                    </div>
                </div>
                <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                    <div className="flex items-center justify-end">
                        {props && props.isLoading === true ? (
                            <ButtonLoader />
                        ) : (
                            <button
                                className="btn btn-pink text-xl text-white font-semibold"
                             onClick={() => props.onSave()}
                            >
                                Save
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
export default OrganisationBasicInformation;